import React from 'react'
import './portifolio.css'
import tata from '../../images/tata.webp'
import santader from '../../images/santander.png'
import acostamento from '../../images/acostamento.jpg'

function portifolio() {
    return (
        <div className='container-line'>
            <div className='content-about'>
                <div className='container-portifolio'>
                    <div className='box-portifolio'>
                        <a href='https://tatazentilbeauty.com.br/'><img src={tata} /></a>
                        <h5>Site Institucional React</h5>
                        <p>Tenho muito orgulho deste projeto por ter participado e feito do zero.</p>
                        <p>Ele foi criado para a lash designer Thais Zentil aumentar sua relevancia na web e divulgar seu trabalho, aumentando assim o número de leads para seu negócio.</p>
                    </div>
                    <div className='box-portifolio'>
                        <a href='https://www.santander.com.br/hotsite/santanderfinanciamentos/'><img src={santader} /></a>
                        <h5>SANTANDER FINANCIAMENTO</h5>
                        <p>Santander Financiamento foi um dos maiores projetos que participei, e também um dos que tenho maior orgulho, nele participei do time de front-end codificando a parte visual do site, ajustando erros e melhorando a usabilidades na navegação do cliente</p>
                        <p>Alem da integração dos componentes front-end com api externa</p>
                    </div>
                    <div className='box-portifolio'>
                        <a href='https://www.acostamento.com.br/'><img src={acostamento} /></a>
                        <h5>LOJA ACOSTAMENTO</h5>
                        <p>Acostamento foi outras loja de peso que atendi durante grande tempo que passei em uma empresa.</p>
                        <p>fornecendo apoio técnico para as maiorias páginas da loja como checkout (área de pagamento), carrinho de compra, promoções, disposição de produtos, crianção de novas páginas como black friday.</p>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default portifolio