import React from 'react'
import styles from './Header.module.css'
import ContainerSmall from '../containerSmall/ContainerSmall'
import ContainerLarge from '../containerLarge/ContainerLarge'
import logo from '../../images/W-Z-logo.svg'
import Llinkedin from '../../images/linkedin.png'
import github from '../../images/github.png'

function Header() {
  return (
    <header className={styles.header}>
      <ContainerLarge>
        <a href='#'>Portifolio Profissional</a>
        <ContainerSmall>
          <a href='#'>Perfil</a>
          <a href='#'>Experiências</a>
          <a href='#'>Perfil</a>
          <a href='#'>Perfil</a>
        </ContainerSmall>
      </ContainerLarge>
    </header >
  )
}

export default Header