import React, { useState } from 'react';
import './App.css';
import Background from './components/atoms/backGround/Background'
import Perfil from './components/perfil/Perfil';
import PerfileU from './components/perfil/PerfilMeu';
import Portifolio from './components/portifolio/portifolio';
import Header from './components/header/Header';

function App() {

  const [key, setKey] = useState()

  const saveKeyHandler = (enteredKey) => {
    setKey(enteredKey)
  }

  console.log('key: ', key)


  return (
    <Background>
      {/* <Header /> */}
      <Perfil />
      <PerfileU />
      <Portifolio />
    </Background>
  );
}

export default App;
